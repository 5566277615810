export const FB_PIXEL_ID = '232574949474124'

export const pageview = (event?: string) => {
  // @ts-ignore
  window.fbq('track', event ? event : 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  // @ts-ignore
  window.fbq('track', name, options)
}
